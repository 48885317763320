import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.error.to-string.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import { RoomStatusType } from '@rose/types';
import { defineComponent } from 'vue';
import { roseDayjs } from '@rose/common-ui';
import Terminzeiten from '@/components/zimmerbelegung/Terminzeiten.vue';
import { isNumber } from 'lodash';
import BehandlerChip from '@/components/BehandlerChip.vue';
import PatientName from '@/components/PatientName.vue';
export default defineComponent({
  components: {
    PatientName: PatientName,
    BehandlerChip: BehandlerChip,
    Terminzeiten: Terminzeiten
  },
  props: {
    selected: {
      type: Boolean,
      required: false,
      "default": false
    },
    room: {
      type: [String, Number],
      required: true
    },
    status: {
      type: String,
      required: true
    },
    appointments: {
      type: Array,
      required: false,
      "default": null
    }
  },
  data: function data() {
    return {
      RoomStatusType: RoomStatusType
    };
  },
  computed: {
    roomText: function roomText() {
      return isNumber(this.room) ? "Zimmer ".concat(this.room.toString(10)) : this.room;
    },
    appointment: function appointment() {
      var _a;

      return ((_a = this.appointments) === null || _a === void 0 ? void 0 : _a[0]) || {};
    },
    doubleAppointment: function doubleAppointment() {
      var _a;

      return ((_a = this.appointments) === null || _a === void 0 ? void 0 : _a[1]) || {};
    },
    isSelected: {
      get: function get() {
        return this.selected;
      },
      set: function set(value) {
        this.$emit('update:selected', value);
      }
    },
    getColor: function getColor() {
      switch (this.status) {
        case RoomStatusType.FREE:
          return '#236923';

        case RoomStatusType.OCCUPIED:
          return '#fdc10c';

        case RoomStatusType.LATE:
          return '#fa7e00';

        case RoomStatusType.INCONSISTENT:
          return '#b71c1c';

        default:
          return '#000';
      }
    },
    statusText: function statusText() {
      switch (this.status) {
        case RoomStatusType.FREE:
          return 'frei';

        case RoomStatusType.OCCUPIED:
          return 'belegt';

        case RoomStatusType.LATE:
          return 'verspätet';

        case RoomStatusType.INCONSISTENT:
          return 'inkonsistent';

        default:
          return '';
      }
    },
    getClass: function getClass() {
      return {
        free: this.status === RoomStatusType.FREE,
        occupied: this.status === RoomStatusType.OCCUPIED,
        late: this.status === RoomStatusType.LATE,
        inconsistent: this.status === RoomStatusType.INCONSISTENT
      };
    },
    getHeight: function getHeight() {
      return this.status === RoomStatusType.FREE ? '0' : '150px';
    },
    terminstart: function terminstart() {
      return roseDayjs(this.appointment.terminstart).format('HH:mm');
    },
    terminende: function terminende() {
      return roseDayjs.tz(roseDayjs.utc(this.appointment.terminstart).format('YYYY-MM-DD HH:mm:ss'), 'Europe/Berlin').add(this.appointment.termindauer, 'minutes').format('HH:mm');
    },
    delayedBehandlungIn: function delayedBehandlungIn() {
      var _a, _b;

      if (((_b = (_a = this.appointment.zeitslots) === null || _a === void 0 ? void 0 : _a.behandlungsZimmerSlot) === null || _b === void 0 ? void 0 : _b.realDauerDiffStart) && this.appointment.zeitslots.behandlungsZimmerSlot.realDauerDiffStart > 0) {
        return this.appointment.zeitslots.behandlungsZimmerSlot.realDauerDiffStart;
      }

      return null;
    },
    delayedBehandlungOut: function delayedBehandlungOut() {
      var _a, _b;

      if (((_b = (_a = this.appointment.zeitslots) === null || _a === void 0 ? void 0 : _a.behandlungsSlot) === null || _b === void 0 ? void 0 : _b.realDauerDiffEnde) && this.appointment.zeitslots.behandlungsSlot.realDauerDiffEnde > 0) {
        return this.appointment.zeitslots.behandlungsSlot.realDauerDiffEnde;
      }

      return null;
    }
  },
  methods: {
    toggleSelected: function toggleSelected() {
      console.log('toggleSelected', this.appointment);
      this.isSelected = !this.isSelected;
    }
  }
});